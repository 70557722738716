import { MenuItem, TheHeaderProps } from "~/components/TheHeader.props"
import { useMenuStore } from "../stores/menu"
// TODO: remove story
import { DefaultNotLogged as searchPopup } from "~/components/Search/SearchbarFocused.stories"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import staticCategories from "~/static/data/static-categories.json"

export const useHeaderConnect = async () => {
  const menuStore = useMenuStore()
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  const { t } = useI18n()
  const isLogged = useIsLogged()

  // Since we were having a problem with "TheFooter" as a server component, we are using a Promise.all to still do the two fetchs synchronously (instead of two async/await consequentially as before)

  const [{ data: headerStripes }] = await Promise.all([
    useAsyncData("header", async () =>
      $fetch("/api/getHeaderStripes", {
        method: "GET"
      })
    )
  ])

  const headers = staticCategories.topCategories?.map((item) => ({
    ...item,
    label: item?.label ?? "",
    path: item?.path ?? "",
    theme: "ghost-black"
  }))
  const customCategory = staticCategories.customCategory

  const topHeader = ref(headers)
  const customButtonHeader = ref(customCategory)

  // ************* END HEADER MENU

  const headerInfo = computed(
    (): TheHeaderProps => ({
      stripes: [],
      isLogged: isLogged.value ? true : false,
      username: user.value?.name || "",
      strikeMessage: "Sciopero dei corrieri fino al 12/06",
      cartQuantity: 0,
      menuLinks: [],
      customButton: undefined,
      searchPopup: searchPopup.args
    })
  )

  return {
    headerInfo,
    topHeader,
    headerStripes,
    customButtonHeader
  }
}
